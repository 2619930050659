.sidebar{
    flex: 1;
    height: calc(100vh - 51px);
    background-color: rgb(251, 251, 255);
    position: sticky;
    top: 51px;
    left: 0;
}

.sidebarWrapper{
    padding: 20px;
    color: #555;
}

.sidebarMenu{
    margin-bottom: 10px;
}
.sidebarTitle{
    font-size: 13px;
    color: rgb(183, 183, 183);
}
.sidebarList{
    list-style: none;
    padding: 5px;
}
.sidebarListItem{
    padding: 5px;
    display: flex;
    align-items: center;
    /* font-size: 12px; */
    border-radius: 10px;
    cursor: pointer;
    transition: .2s;
    margin-bottom: 3px;
}
.sidebarListItem.active , .sidebarListItem:hover{
    background-color: rgb(219, 219, 252);
}
.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}