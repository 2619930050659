.WidgetLg{
    flex: 1;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    padding: 40px;
    /* position: relative; */
}

.widgetLgTitle{
    font-size: 22px;
    font-weight: 600;
}

.widgetLgTable{
    width: 100%;
    border-spacing: 20px;
}

.widgetLgTh{
    text-align: left;
}

.widgetLgUser{
    display: flex;
    align-items: center;
    font-weight: 600;
}

.widgetLgDate, .widgetLgAmount{
    font-weight: 400;
}

.widgetLgImg{
    width: 40px;
    height: 40px;
    object-fit: cover;    
    border-radius: 50%;
    margin-right: 20px;
}

.widgetLgButton{
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
}

.widgetLgButton.approved{
    background-color: #e5faf2;
    color: #3bb077;
}
.widgetLgButton.declined{
    background-color: #fff0f1;
    color: #d95087;
}
.widgetLgButton.returned{
    background-color: #d95050;
    color: #ffffff;
}
.widgetLgButton.pending{
    background-color: #ebf1fe;
    color: #2a7ade;
}

.widgetViewButton{
    display: flex;
    align-items: center;
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    background-color: #eeeef7;
    color: #555;
    cursor: pointer;
    transition: all .2s;
}
.widgetViewButton:hover{
    background-color: #e6e6e6d1;
    transform: translateY(-2px);

}
.widgetViewIcon{
    font-size: 16px !important;
    margin-right: 5px;
}