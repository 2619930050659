.approved{
    background-color: #e5faf2;
    color: #3bb077;
}
.declined{
    background-color: #fff0f1;
    color: #d95087;
}
.pending{
    background-color: #ebf1fe;
    color: #2a7ade;
}
.delivered{
    background-color: #9fe69a;
    color: #058900;
}
.returned{
    background-color: #e9b7b7;
    color: #cc0606;
}
.approved.circle{
    background-color: #3bb077;
}
.declined.circle{
    background-color: #d95087;
}
.pending.circle{
    background-color: #2a7ade;
}
.delivered.circle{
    background-color: #058900;
}
.returned.circle{
    background-color: #b11e59;
}