.topbar {
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: sticky;
    top: 0;
    border-bottom: 1px solid rgba(139, 151, 93, 0.278);
    z-index: 2;
}

.topbarWrapprt {
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-size: 30px;
    color: darkblue;
    font-weight: bold;
    cursor: pointer;
}

.topRight {
    display: flex;
    align-items: center;
}

.topbarIconsContainer {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
}

.topIconBadge {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.logout{
    display: flex;
    align-items: center;
    gap: 8px;
    border: solid 1px lightgray;
    margin-left: 20px;
    padding: 2px 4px;
    transition: 300ms ease-in-out;
}
.logout:hover{
    background-color: rgba(216, 216, 216, 0.637);
}