.newProduct {
    flex: 4;
}

.from-title {
    font-size: 30px;
}

.wrapper {
    display: flex;
    justify-content: space-evenly;
    padding-inline: 40px;
}



.left {
    flex: 2;
}

.right {
    flex: 2;
}

.addProductForm {
    flex: 4;
    margin-top: 10px;
    margin-bottom: 100px;
}

.gallery {
    /* width: 100%; */
    flex-wrap: wrap;
    background-color: #f8fafd;
    /* justify-content: space-evenly; */
    border: 0.125rem dashed rgba(231, 234, 243, .7);
    border-radius: 0.5rem;
    padding: 3rem 3rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
}


#file{
    width: 100%;
    height: 200px;
    display: none;
}

.img {
    margin-left: 10px;
    margin-bottom: 10px;
    width: 80px;
    height: 80px;
    border: 1px solid #00c9a7;
    border-radius: 5px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    font-size: 80px;
    font-weight: 200;
    display: grid;
    color: #00c9a7;
    place-content: center;
}


.soldInput {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
}

.addProductItem {
    width: 350px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.addProductItem>label {
    /* margin-top: 10px; */
    /* font-weight: 600; */
    /* color: black; */
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    color: #1e2022;
}


input {
    background-color: transparent;
    /* z-index: 999; */
    margin: 0;
    /* appearance: none; */
    background: transparent;
    border: 1px grey solid;
    /* background-clip: padding-box; */
    border-radius: 5px;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    /* width: 100%; */
    padding: 0.7857142857em 0.7857142857em;
    /* padding-top: 1.2em; */
    word-break: normal;
    line-height: inherit;
}

textarea {
    min-height: 80px;
    min-width: 100%;
    max-width: 100%;
    border-radius: 7px;
    font-size: 18px;
    padding: 13px;
}

.addProductItem>select {

    padding: 10px;
}

.newVariant {
    display: inline-block;
    padding: inherit;
    background-color: #0000008f;
    color: white;
    cursor: pointer;
    border-radius: 20px;
    width: 300px;
    text-align: center;
    font-size: 30px;
    transition: .3s ease;
}

.newVariant:hover {
    background-color: #3e00ea8f;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.form-control {
    display: block;
    width: 40px;
    font-size: 23px !important;
    padding: 0.6125rem 1rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1e2022;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid rgba(231, 234, 243, .7);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.3125rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control.quantity {
    width: 130px;
}

.btn {
    --background: #362a89;
    --background-hover: #291f6e;
    --text: #fff;
    --icon: #fff;
    display: -webkit-box;
    display: flex;
    outline: none;
    cursor: pointer;
    border: 0;
    min-width: 70px;
    padding: 9px 20px 9px 12px;
    border-radius: 11px;
    line-height: 24px;
    font-family: inherit;
    font-weight: 600;
    font-size: 14px;
    overflow: hidden;
    color: var(--text);
    background: var(--b, var(--background));
    -webkit-transition: background 0.4s, -webkit-transform 0.3s;
    transition: background 0.4s, -webkit-transform 0.3s;
    transition: transform 0.3s, background 0.4s;
    transition: transform 0.3s, background 0.4s, -webkit-transform 0.3s;
}

.btn-remove {
    background: rgb(194, 62, 62);
}

/* //////////// main img ///////////  */
.input-preview__src {
	display: none;
}

.main-img{
    width: 100%;
}

.input-preview {
	border: dashed rgb(168, 168, 168) 2px;
	border-radius: 0.5em;
	/* width: 90vw; */
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	transition: ease-in-out 750ms;
    background-color: rgb(221, 221, 221);
}

.input-preview::after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	text-align: center;
	transform: translate(-50%,-50%);
	content: "+";
	/* content: "*Choose The Main Photo...! 📷*"; */
    color: rgb(95, 95, 95);
	/* font-style: italic; */
	font-size: 2em;
    font-weight: 400;
}

.has-image::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(50, 50, 50, 0.5);
	content: " ";
	transition: ease-in-out 750ms;
}

.has-image::after {
	content: "";
	color: white;
}
